<template>
  <v-data-table
    :headers="headers"
    :items="exchangeRates"
    sort-by="name"
    :search="search"
    class="elevation-1"
    :loading="loading"
  >
    <template v-slot:top>
      <v-toolbar flat class="pa-3">
        <!-- <v-toolbar-title>Users</v-toolbar-title> -->
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Exchange Rates"
          single-line
          hide-details
        ></v-text-field>

        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="newExchangeRate">
          New Exchange Rate
        </v-btn>
        <v-dialog v-model="dialog" :fullscreen="true"> 
          <exchange-rate-form v-if="exchangeRate" :key="'edit-' + formKey" @close-dialog="close"  :item="exchangeRate" />
          <exchange-rate-form  v-else :key="'add-' + formKey" @close-dialog="close" />
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeDelete"
                :disabled="loading"
                >Cancel</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="deleteItemConfirm"
                :loading="loading"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import ExchangeRateService from "../../../services/exchange-rate.service";
const ExchangeRateForm = () => import("./form.vue");


export default {
  data: () => ({
    formKey: 1,
    search: "",
    dialog: false,
    dialogDelete: false,
    loading: false,
    validExchangeRate: true,
    currencies: [
      /* { code: "USD", name: "US Dollars", symbol: "$" },
      { code: "GBP", name: "Great British Pounds", symbol: "£" },
      { code: "EUR", name: "Euros", symbol: "€" },
      { code: "GMD", name: "Gambian Dalasi", symbol: "GMD" }, */
    ],
    headers: [
      {
        text: "Service",
        align: "start",
        sortable: true,
        value: "service",
      },
      {
        text: "From Currency",
        align: "start",
        sortable: true,
        value: "fromCurrency",
      },
      { text: "To Currency", value: "toCurrency" },
      { text: "Rate", value: "rate" },
      { text: "Status", value: "status" },
      { text: "Created", value: "createdAt" },
      { text: "Last Updated At", value: "updatedAt" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    exchangeRate: {
      code: "",
      from_currency: "",
      to_currency: "",
      status: "",
      rate: 0,
    },
    status: [
      { text: "Active", value: "ACTIVE" },
      { text: "Inactive", value: "INACTIVE" },
    ],
    // currencies: ["Euro", "Us Dollar", "Pounds", "Dalasis"],

    codeRules: [
      (v) => !!v || "Code is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Code";
        }
        return true;
      },
    ],
    rateRules: [
      (v) => !!v || "Rate is required",
      (v) => {
        if (!(v && v.length != 0)) {
          return "Invalid Code";
        }
        return true;
      },
    ],
    fromCurrencyRules: [
      (v) => !!v || "From Currency is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Currency";
        }
        return true;
      },
    ],
    toCurrencyRules: [
      (v) => !!v || "To Currency is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Currency";
        }
        return true;
      },
    ],
    statusRules: [
      (v) => !!v || "Status is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Status";
        }
        return true;
      },
    ],
    desserts: [],
    editedIndex: -1,
    exchangeRates: [],
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),
  components: {
    ExchangeRateForm
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Exchange Rate"
        : "Edit Exchange Rate";
    },

  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    newExchangeRate() {
      this.setFormKey();
      this.exchangeRate = null;
      this.dialog = true;
    },
    async getCurrency() {
      const response = await ExchangeRateService.getCurrencies();
      //console.log("get currencies", response.data.currencies)
      this.currencies = response.data.currencies;
      console.log("cur", this.currencies);
    },
    async addExchangeRate() {
      try {

        this.validExchangeRate = this.$refs.form.validate();
        if (this.validExchangeRate) {
          this.loading = true;

          await ExchangeRateService.save(this.exchangeRate);
          this.loading = false;
          this.dialog = false;

          this.$store.commit(
            "mShowAlert",
            "Exchange Rate Created Successfully."
          );

          this.loadExchangeRates();
          this.exchangeRate = null;
        } else {
          this.$store.commit("mShowAlert", "Fill missing fields ");
        }
      } catch (error) {
        let message = error;
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message;
        }
        this.$store.commit("mShowAlert", message);
        this.loading = false;
      }
    },

    initialize() {
      this.desserts = [];
    },
    setFormKey() {
      this.formKey = Math.floor(new Date().getTime() / 1000);
      console.log("this.formKey", this.formKey);
    },
    editItem(item) {
      this.setFormKey();
      this.exchangeRate = { ...item };
      this.dialog = true;
    },
    deleteItem(item) {
      this.item = item;
      // this.editedIndex = this.desserts.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        const item = this.item;
        this.loading = true;
        await ExchangeRateService.delete(item.code);
        this.closeDelete();
        this.loading = false;
        this.loadExchangeRates();
      } catch (error) {
        this.loading = false;
        console.log("delete error", error);
      }
    },

    close() {
      this.dialog = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
      this.loadExchangeRates();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    async loadExchangeRates() {
      this.loading = true;
      try {
        const response = await ExchangeRateService.getList();
        this.exchangeRates = response.data;
      } catch (error) {
        console.log("acl_exchangerates report error", error);
      }
      this.loading = false;
    },
  },
  mounted() {
    this.loadExchangeRates();
    this.getCurrency();
  },
};
</script>