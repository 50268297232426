import ApiService from "./api.service";

const ExchangeRateService = {
  getList() {
    return ApiService.query("/pay/exchange-rate");
  },
  getCurrencies(){
    return ApiService.query("/p/pay/config")
  },
  save(data) {
    return ApiService.post("/pay/exchange-rate", data);
  },
  delete(code) {
    return ApiService.delete("/pay/exchange-rate/" + code);
  },
};
export default ExchangeRateService;
